import ReactDOM from "react-dom";
import React from "react";
import Footer from "../../templates/components/footer/Footer";
import carousel from "../../templates/components/carousel/carousel";
import nav from "../../templates/components/nav/nav";
import posts from "../../templates/components/posts/posts";

carousel();
nav();
posts();

const themeBase = document.currentScript.getAttribute('data-theme-base');

const navs = [
  {
    title: "Top Brands",
    links: [
      {
        children: "Nike",
        href: "https://www.laced.com/nike",
      },
      {
        children: "Adidas",
        href: "https://www.laced.com/adidas",
      },
      {
        children: "Air Jordan",
        href: "https://www.laced.com/air-jordan",
      },
      {
        children: "Yeezy",
        href: "https://www.laced.com/yeezy",
      },
    ],
  },
  {
    title: "Best Sellers",
    links: [
      {
        children: "Yeezy 350 Black",
        href: "https://www.laced.com/products/yeezy-boost-350-v2-black",
      },
      {
        children: "Yeezy 500 Bone White",
        href: "https://www.laced.com/products/yeezy-500-bone-white",
      },
      {
        children: "Yeezy 350 Cloud",
        href: "https://www.laced.com/products/yeezy-boost-350-v2-cloud-white",
      },
    ],
  },
  {
    title: "About Us",
    links: [
      { children: "FAQ/Help", href: "https://help.laced.com" },
      { children: "My Account", href: "https://www.laced.com/account" },
      {
        children: "Terms & Conditions",
        href: "https://www.laced.com/pages/terms-and-conditions",
      },
    ],
  },
  {
    title: "Social",
    links: [
      {
        children: "Instagram",
        href: "https://www.instagram.com/lacedhq/",
      },
      { children: "Twitter", href: "https://twitter.com/lacedhq" },
      { children: "help@laced.com", href: "mailto:help@laced.com" },
    ],
    classes: ["hide-mobile"],
  },
];

ReactDOM.render(<Footer navs={navs} themeBase={themeBase} />, document.getElementById("footer"));
