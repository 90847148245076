import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FeatureBar from "./FeatureBar.js";
import FooterAccordion from "./FooterAccordion.js";

import {
  logoSrc,
  instagram,
  twitter,
  applePay,
  googlePay,
  mastercard,
  visa,
  americanExpress
} from "./images";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.trustPilotRef = React.createRef();
  }

  componentDidMount() {
    window.Trustpilot &&
      window.Trustpilot.loadFromElement(this.trustPilotRef.current);
  }

  flex() {
    const assetsBase = `${this.props.themeBase}/dist/assets`

    return (
      <div className="ftr__flex">
        <div className="social-nav">
          <h4 className="ftr__title">
            <img
              className="ftr__logo"
              src={assetsBase + logoSrc}
              alt="Laced"
            />
          </h4>
          <ul className="ftr-social hide-desktop">
            <li
              className="ftr-social__item"
              key={`ftr-social__item--instagram`}
            >
              <a
                className="ftr-social__link"
                href="https://www.instagram.com/lacedhq/"
              >
                <img
                  className="ftr__logo"
                  src={assetsBase + instagram}
                  alt="Instagram"
                />
              </a>
            </li>
            <li className="ftr-social__item" key={`ftr-social__item--twitter`}>
              <a
                className="ftr-social__link"
                href="https://twitter.com/lacedhq"
              >
                <img
                  className="ftr__logo"
                  src={assetsBase + twitter}
                  alt="Twitter"
                />
              </a>
            </li>
          </ul>
          <ul className="ftr-contact hide-desktop">
            <li className="ftr-contact__item" key={`ftr-contact__item--email`}>
              <a className="ftr-contact__link" href="mailto:help@laced.com">
                <p className="ftr-contact__text">help@laced.com</p>
              </a>
            </li>
          </ul>
        </div>
        <ul className="ftr-about-us hide-desktop">
          <li>
            <a className="ftr-about-us__link" href="https://blog.laced.com">
              <span className="ftr-about-us__text">Blog</span>
            </a>
          </li>
          <li>
            <a className="ftr-about-us__link" href="https://www.laced.com/account">
              <span className="ftr-about-us__text">My Account</span>
            </a>
          </li>
          <li>
            <a className="ftr-about-us__link" href="https://www.laced.com/pages/faq">
              <span className="ftr-about-us__text">FAQ/Help</span>
            </a>
          </li>
          <li>
            <a
              className="ftr-about-us__link"
              href="https://www.laced.com/pages/terms-and-conditions"
            >
              <span className="ftr-about-us__text">Terms & Conditions</span>
            </a>
          </li>
        </ul>
        {this.props.navs.map((nav, key) => (
          <FooterAccordion key={key} item={nav} />
        ))}
      </div>
    );
  }

  footer() {
    const assetsBase = `${this.props.themeBase}/dist/assets`

    return (
      <div className="ftr__footer">
        <div className="ftr__logos">
          <div className="ftr__logo--container">
            <img
              className="ftr__logo"
              src={assetsBase + applePay}
              alt="Apple Pay"
            />
          </div>
          <div className="ftr__logo--container">
            <img
              className="ftr__logo"
              src={assetsBase + googlePay}
              alt="Google Pay"
            />
          </div>
          <div className="ftr__logo--container">
            <img
              className="ftr__logo"
              src={assetsBase + mastercard}
              alt="Mastercard"
            />
          </div>
          <div className="ftr__logo--container">
            <img
              className="ftr__logo"
              src={assetsBase + visa}
              alt="Visa"
            />
          </div>
          <div className="ftr__logo--container">
            <img
              className="ftr__logo"
              src={assetsBase + americanExpress}
              alt="American Express"
            />
          </div>
        </div>
        <div className="ftr__footer__inner">
          <div className="ftr__copyright">
            <p>All Rights Reserved &copy; {new Date().getFullYear()} Laced</p>
            <p className="hide-mobile">&nbsp;|&nbsp;</p>
            <p>7 Bell Yard, London, WC2A 2JR &bull; Company No. 09541333</p>
          </div>
          <div className="ftr__trustpilot">
            {/* TrustBox widget - Micro TrustScore */}
            <div
              className="trustpilot-widget"
              data-locale="en-GB"
              data-template-id="5419b637fa0340045cd0c936"
              data-businessunit-id="5c812ed9871dac0001ea1384"
              data-style-width="100%"
              data-style-height="60px"
              data-theme="dark"
              ref={this.trustPilotRef}
            >
              <a
                href="https://uk.trustpilot.com/review/laced.com"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>{" "}
            </div>
            {/* End TrustBox widget */}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <FeatureBar />
        <footer className="ftr">
          <div className="ftr__inner">
            {this.flex()}
            {this.footer()}
          </div>
        </footer>
      </Fragment>
    );
  }
}

Footer.propTypes = {
  navs: PropTypes.array.isRequired,
  themeBase: PropTypes.string.isRequired
};

export default Footer;
